import * as React from 'react';
import Layout from '../../../components/Layout';
import {
  filterifyQuery,
  serializeFilter
} from '@wooindex/common/filter';
import Dashboard from '../../../components/Dashboard';
import { CsvExportRequest, Filter } from '@wooindex/common/types';
import { useRouter } from '../../../components/i18n';
import createGetServerSideProps, { PageProps } from 'lib/createGetServerSideProps';
import { GetServerSideProps, Redirect } from 'next';
import fetchFromApi, { FetchContext } from 'lib/fetchFromApi';

const getBaseServerSideProps = createGetServerSideProps();

// TODO: contribute this upstream:
export type GetServerSidePropsResultPatched<P> = {
  props?: P
  redirect?: undefined
  notFound?: undefined
} | {
  props?: undefined
  redirect?: Redirect
  notFound?: undefined
} | {
  props?: undefined
  redirect?: undefined
  notFound?: true
}

async function getCsvExportRequest (ctx: FetchContext, id: number): Promise<CsvExportRequest | null> {
  try {
    return await fetchFromApi(ctx, 'get', `/csv-exports/${encodeURIComponent(id)}`).json() as CsvExportRequest;
  } catch (err) {
    return null;
  }
}

interface IndexPageProps extends PageProps {
  initialColumns: string[] | null
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const { basedOn } = ctx.query;

  const [
    result,
    basedOnRequest
  ] = await Promise.all([
    getBaseServerSideProps(ctx) as Promise<GetServerSidePropsResultPatched<PageProps>>,
    typeof basedOn === 'string'
      ? getCsvExportRequest(ctx, Number(basedOn))
      : null
  ]);

  return {
    ...result,
    props: {
      ...result.props,
      initialColumns: basedOnRequest?.columns || null
    }
  };
};

function fingerPrint (filter: Filter): string {
  return serializeFilter(filter).toString();
}

const emptyFilter = filterifyQuery({});
const emptyFilterKey = fingerPrint(emptyFilter);

export default function DashboardPage (props: IndexPageProps) {
  const [filter, setFilter] = React.useState<Filter>();
  const router = useRouter();

  React.useEffect(() => {
    // Reset the basedOn parameter to avoid reinitializing the columns when the user refreshes
    const { basedOn, ...query } = router.query;
    delete query.lang;
    if (typeof basedOn !== 'undefined') {
      router.replace({
        pathname: router.pathname,
        query
      }, undefined, { shallow: true });
    }
  }, [router]);

  React.useEffect(() => {
    if (router.isReady) {
      setFilter(filterifyQuery(router.query));
    }
  }, [router.isReady, router.query]);

  const onFilterChange = React.useCallback((newFilter: Filter) => setFilter(newFilter), []);

  // Following logic handles syncing the url query with the filter selection
  const prevPageFilterKey = React.useRef(emptyFilterKey);
  const prevUrlFilterKey = React.useRef(emptyFilterKey);
  React.useEffect(() => {
    const urlFilter = filterifyQuery(router.query);
    const urlFilterKey = fingerPrint(urlFilter);
    const pageFilter = filter || emptyFilter;
    const pageFilterKey = fingerPrint(pageFilter);

    const pageFilterChanged = prevPageFilterKey.current !== pageFilterKey;
    const queryFilterChanged = prevUrlFilterKey.current !== urlFilterKey;

    if (pageFilterChanged) {
      // The user updated the filer in the UI, we need to update the url to reflect the change
      router.push({
        pathname: router.pathname,
        query: pageFilter
      }, undefined, {
        shallow: true,
        scroll: false
      });
    } else if (queryFilterChanged) {
      // The page url has been updated to a new filter, we need to make the state reflect this change
      setFilter(urlFilter);
    }

    prevUrlFilterKey.current = urlFilterKey;
    prevPageFilterKey.current = pageFilterKey;
  }, [filter, router]);

  return (
    <Layout active='dashboard' loginRequired>
      <Dashboard value={filter} onChange={onFilterChange} initialColumns={props.initialColumns} />
    </Layout>
  );
}
