import {
  Card,
  CardHeader,
  Checkbox,
  createStyles,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  // Skeleton, // uncomment when moving to material UI v5 (see https://github.com/mui-org/material-ui/pull/22740)
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import { ControlledProps } from './utils';
import * as React from 'react';
// Remove when upgrading to material UI v5
import { Skeleton } from '@material-ui/lab';
import { complement, union } from '@wooindex/common/collections';

const useStyles = makeStyles((theme) =>
  createStyles({
    loading: {},
    root: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 8,
      marginBottom: 4
    },
    cardHeader: {
      padding: theme.spacing(1, 2)
    },
    list: {
      backgroundColor: theme.palette.background.paper,
      overflow: 'auto',
      '$loading &': {
        overflow: 'hidden'
      }
    },
    button: {
      margin: theme.spacing(0.5, 0)
    }
  })
);

interface SelectionItemProps {
  disabled?: boolean
  checked?: boolean
  onClick?: () => void
  primary?: React.ReactNode
  secondary?: React.ReactNode
}

function SelectionItem ({ disabled, checked, onClick, primary, secondary }: SelectionItemProps) {
  return (
    <ListItem role='listitem' button onClick={onClick} disabled={disabled}>
      <ListItemIcon>
        <Checkbox
          checked={checked}
          tabIndex={-1}
          disableRipple
        />
      </ListItemIcon>
      <ListItemText primary={primary} secondary={secondary} />
    </ListItem>
  );
}

interface ColumnSelectionListProps<T> extends ControlledProps<T[]> {
  title?: string
  options?: readonly T[]
  getOptionPrimaryText?: (option: T, i: number) => React.ReactNode
  getOptionSecondaryText?: (option: T, i: number) => React.ReactNode
  loading?: boolean
  getOptionDisabled?: (option: T) => boolean
}

export default function SelectionList<T> ({
  title = 'Available options',
  loading,
  value,
  onChange,
  options = [],
  getOptionDisabled = () => false,
  getOptionPrimaryText = String,
  getOptionSecondaryText = () => null
}: ColumnSelectionListProps<T>) {
  const classes = useStyles();

  const availableOptions = options.filter(option => !getOptionDisabled(option));
  const allAvailableChecked = availableOptions.every(availableOption => value.includes(availableOption));
  const someAvailableChecked = availableOptions.some(availableOption => value.includes(availableOption));

  const handleToggleAll = () => {
    if (allAvailableChecked) {
      onChange(complement(value, availableOptions));
    } else {
      onChange(union(value, availableOptions));
    }
  };

  const handleToggle = (newOption: T) => () => {
    const newValue = value.includes(newOption) ? complement(value, [newOption]) : union(value, [newOption]);
    onChange(newValue);
  };

  return (
    <Card variant='outlined' className={clsx(classes.root, { [classes.loading]: loading })}>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll}
            checked={allAvailableChecked}
            indeterminate={someAvailableChecked && !allAvailableChecked}
            disabled={availableOptions.length <= 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
          }
        title={title}
        subheader={`${value.length}/${options.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component='div' role='list'>
        {loading
          ? (
              new Array(10).fill(null)
                .map((option, i: number) => <SelectionItem key={`empty-${i}`} disabled primary={<Skeleton variant='text' />} />)
            )
          : (
              options.map((option, i: number) => {
                const disabled = getOptionDisabled(option);
                return (
                  <SelectionItem
                    key={`option-${i}`}
                    disabled={disabled}
                    checked={value.includes(option)}
                    onClick={handleToggle(option)}
                    primary={getOptionPrimaryText(option, i)}
                    secondary={getOptionSecondaryText(option, i)}
                  />
                );
              })
            )}
      </List>
    </Card>
  );
}
