"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInterval = exports.encodePassthrough = exports.decodePassthrough = void 0;
/**
 * Decode a Paddle passthrough value
 */
function decodePassthrough(passthrough) {
    return JSON.parse(passthrough);
}
exports.decodePassthrough = decodePassthrough;
/**
 * Encode a Paddle passthrough value
 */
function encodePassthrough(passthrough) {
    return JSON.stringify(passthrough);
}
exports.encodePassthrough = encodePassthrough;
var PlanInterval;
(function (PlanInterval) {
    PlanInterval[PlanInterval["daily"] = 1] = "daily";
    PlanInterval[PlanInterval["weekly"] = 7] = "weekly";
    PlanInterval[PlanInterval["monthly"] = 31] = "monthly";
    PlanInterval[PlanInterval["yearly"] = 365] = "yearly";
})(PlanInterval || (PlanInterval = {}));
function getInterval(planKey) {
    switch (planKey) {
        case 'monthly': return PlanInterval.monthly;
        case 'yearly': return PlanInterval.yearly;
        default: throw new Error("Not a recurring plan " + planKey);
    }
}
exports.getInterval = getInterval;
