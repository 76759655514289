/* eslint-disable react/prop-types */

import * as React from 'react';
import {
  makeStyles,
  Toolbar,
  Typography,
  Button,
  Tooltip,
  IconButton
} from '@material-ui/core';
import { AttributeKey, CriteriumStatus, Filter, QuantizedScore, Review, ReviewList } from '@wooindex/common/types';
import Favicon from '../Favicon';
import { useI18n } from '../i18n';
import DashBoardCard from './DashboardCard';
import ReviewDetailsDialog from './ReviewDetailsDialog';
import useSWR from 'swr';
import { filterifyQuery, queryfyFilter } from '@wooindex/common/filter';
import { toSearchParams } from '@wooindex/common/searchParams';
import CsvExportDialog from './CsvExportDialog';
import { useUser } from '../../lib/user';
import usePersistedState from '../../lib/usePersistedState';
import FlexFill from '../FlexFill';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import MeterBar from '../MeterBar';
import TechnologyIcon from '../TechnologyIcon';
import {
  ATTRIBUTES,
  AttributeType,
  AttributeValueType,
  AttributeValueTypeOf,
  ATTRIBUTE_DESCRIPTORS,
  QUANTIZED_SCORE_DISPLAY_NAME
} from '@wooindex/common/attributes';
import FlagIcon from '../FlagIcon';
import { useHasFeature } from '../FeatureFlags';
import DataTable from '../DataTable';
import { CellProps, Column } from 'react-table';
import CriteriumStatusDisplay from './CriteriumStatusDisplay';
import ColumnSelector from '../ColumnSelector';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import useColumnSelection from '../useColumnSelection';
import CtaBadge from '../CtaBadge';
import { REVIEWS_LIST_MAX_PAGES, REVIEWS_LIST_PAGE_SIZE } from '@wooindex/common/constants';

const DEFAULT_COLUMNS = ['favicon', 'url', 'title', 'country', 'technologies'];

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  disabled: {
    pointerEvents: 'none'
  },
  toolbar: {
    margin: theme.spacing(4, 0)
  },
  toolbarActions: {
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  },
  technologyCellContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  },
  noWrap: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  favicon: {
    marginRight: theme.spacing(1)
  },
  pagination: {
    display: 'inline-flex',
    alignItems: 'center'
  }
}));

interface TechnologyCellContentProps {
  value?: string[] | null
  limit?: number
}

function TechnologyCellContent ({ value, limit = 3 }: TechnologyCellContentProps) {
  const classes = useStyles();
  const { numberFormat } = useI18n();
  const visibleItems = value ? value.slice(0, limit) : [];
  const overflowCount = value ? Math.max(value.length - limit, 0) : 0;
  return (
    <div className={classes.technologyCellContent}>
      {visibleItems.map(technology => <TechnologyIcon key={technology} id={technology} fontSize={16} />)}
      {overflowCount > 0 ? <span>+{numberFormat.basic.format(overflowCount)}</span> : null}
    </div>
  );
}

type Maybe<T> = T | null | undefined
type ReviewCellProps<T> = CellProps<Review, Maybe<T>>
type CellRenderer<T> = (params: ReviewCellProps<T>) => JSX.Element
type AttributeTypeColumnDef<T> = Partial<Omit<Column<Review>, 'Cell'> & {
  Cell: CellRenderer<T>
}>

function TextCellContent ({ children }: {children: React.ReactNode}) {
  const classes = useStyles();
  // by default let's just render with overflow ellipses
  return <div className={classes.noWrap}>{children}</div>;
}

function StringCell ({ value }: ReviewCellProps<string>) {
  return <TextCellContent>{value}</TextCellContent>;
}

function NumberCell ({ value }: ReviewCellProps<number>) {
  return <TextCellContent>{value}</TextCellContent>;
}

function ListCell ({ value }: ReviewCellProps<string[]>) {
  return <TextCellContent>{value?.join(', ')}</TextCellContent>;
}

function StatusCell ({ value }: ReviewCellProps<CriteriumStatus>) {
  return <CriteriumStatusDisplay value={value} />;
}

function QuantizedCell ({ value }: ReviewCellProps<QuantizedScore>) {
  return (
    <Tooltip title={value ? QUANTIZED_SCORE_DISPLAY_NAME[value] : 'Unknown'}>
      <MeterBar value={value} />
    </Tooltip>
  );
}

function DateCell ({ value }: ReviewCellProps<number | Date>) {
  const { dateTimeFormat } = useI18n();
  return <>{value ? dateTimeFormat.mediumDate.format(value) : null}</>;
}

/**
 * Column definitions by attribute datatype
 */
const columnDefByType: {
  [type in AttributeType]: AttributeTypeColumnDef<AttributeValueType[type]>
} = {
  string: { Cell: StringCell },
  number: {
    align: 'right',
    Cell: NumberCell
  },
  list: { Cell: ListCell },
  status: {
    minWidth: 50,
    width: 130,
    Cell: StatusCell
  },
  quantized: {
    align: 'center',
    minWidth: 90,
    width: 155,
    Cell: QuantizedCell
  },
  date: {
    minWidth: 120,
    width: 120,
    align: 'right',
    Cell: DateCell
  }
};

function UrlCell ({ value }: ReviewCellProps<string>) {
  const classes = useStyles();
  return (
    <>
      <Favicon className={classes.favicon} fontSize='small' url={value!} />
      <span className={classes.noWrap}>{value}</span>
    </>
  );
}

function CountryCell ({ value }: ReviewCellProps<string>) {
  return <>{value ? <FlagIcon countryCode={value} /> : null}</>;
}

function technologiesCell ({ value, column }: ReviewCellProps<string[]>) {
  const padding = 2 * 16;// cell padding
  const overflowCountWidth = 36;// width of the overflow text (+ margin)
  const techIconWidth = 24;// width of a single icon (+ margin)
  // We can calculate how many icons we can display based on the column width
  const columnWidth = typeof column.width === 'number'
    ? Math.min(Math.max(column.minWidth || 0, column.width), column.maxWidth || Infinity)
    : undefined;
  const limit = typeof columnWidth === 'number'
    ? Math.floor((columnWidth - overflowCountWidth - padding) / techIconWidth)
    : 3;
  return <TechnologyCellContent value={value} limit={limit} />;
}

/**
 * Column definitions overrides per attribute
 */
const columnDefByAttr: {
  [key in AttributeKey]?: AttributeTypeColumnDef<AttributeValueTypeOf<key>>
} = {
  url: {
    minWidth: 100,
    width: 250,
    Cell: UrlCell
  },
  title: {
    minWidth: 250,
    width: 500
  },
  score: {
    minWidth: 75,
    width: 75
  },
  country: {
    align: 'center',
    minWidth: 90,
    width: 90,
    Cell: CountryCell
  },
  technologies: {
    minWidth: 140,
    width: 140,
    Cell: technologiesCell
  }
};

function getColumnHeader (attr: string) {
  return ATTRIBUTE_DESCRIPTORS[attr as AttributeKey]?.name ?? <></>;
}

const columns = ATTRIBUTES.map(attr => {
  return {
    accessor: attr,
    minWidth: 50,
    Header: getColumnHeader(attr),
    ...columnDefByType[ATTRIBUTE_DESCRIPTORS[attr].type],
    ...columnDefByAttr[attr]
  } as (Column<Review> & { accessor: string });
});

export interface ReviewsDataProps {
  disabled?: boolean
  value: Filter
  onChange: (newValue: Filter) => void
  className?: string
  initialColumns?: string[] | null
}

export default function ReviewsData ({ value, onChange, disabled, className, initialColumns }: ReviewsDataProps) {
  const stickyColumn = useHasFeature('sticky');

  const classes = useStyles();
  const { numberFormat } = useI18n();
  const { user } = useUser();
  const [page, setPage] = React.useState<number>(1);

  const query = toSearchParams(queryfyFilter(value));
  // used to detect changes in the filter (excludes page)
  const filterKey = query.toString();
  query.set('page', String(page));

  React.useEffect(() => {
    // reset page when a new filter is selected
    setPage(1);
  }, [filterKey]);

  const {
    data: reviews,
    isValidating: reviewsIsValidating
  } = useSWR<ReviewList>(user ? `/api/data/reviews?${query}` : null, undefined);

  const [selectedReview, setSelectedReview] = React.useState<Review>();
  const [reviewDetailsDialogOpen, setReviewDetailsDialogOpen] = React.useState(false);

  const {
    isColumnSelectionDisabled,
    getColumnCtaLabel,
    getColumnCtaMessage,
    sanitizeColumnSelection
  } = useColumnSelection();

  const [visibleColumnsStored, setVisibleColumns] = usePersistedState('reviews-table-visible-columns', {
    initialValue: initialColumns || undefined
  });

  const visibleColumns = React.useMemo(() => {
    // let's make sure to validate whatever comes out of storage
    if (visibleColumnsStored && Array.isArray(visibleColumnsStored)) {
      return sanitizeColumnSelection(visibleColumnsStored);
    } else {
      return DEFAULT_COLUMNS;
    }
  }, [visibleColumnsStored, sanitizeColumnSelection]);

  const handleColumsChange = React.useCallback((newColumns: string[]) => {
    setVisibleColumns(sanitizeColumnSelection(newColumns));
  }, [sanitizeColumnSelection, setVisibleColumns]);

  const [csvExportDialogOpen, setCsvExportDialogOpen] = React.useState(false);

  const handleCreateCsvExportClick = () => {
    setCsvExportDialogOpen(true);
  };

  const handleCsvExportDialogClose = () => {
    setCsvExportDialogOpen(false);
  };

  const handleClear = () => {
    onChange(filterifyQuery({}));
  };

  const handleRowClick = ({ row }: { row: Review }) => {
    setSelectedReview(row);
    setReviewDetailsDialogOpen(true);
  };

  const reviewsLoading = !reviews && reviewsIsValidating;

  const totalRows = reviews?.total ?? 0;
  const lastPage = Math.ceil(totalRows / REVIEWS_LIST_PAGE_SIZE);

  const renderColumnHeaderWithCta = (attr: string) => {
    const ctaLabel = getColumnCtaLabel(attr);
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <span>{getColumnHeader(attr)}</span>
        {ctaLabel ? <CtaBadge>{ctaLabel}</CtaBadge> : null}
      </div>
    );
  };

  return (
    <div className={clsx(classes.root, { [classes.disabled]: disabled }, className)}>
      <Toolbar disableGutters className={classes.toolbar}>
        {reviews
          ? (
            <Typography variant='h6'>
              {numberFormat.basic.format(reviews.total)} {reviews.total === 1 ? 'url' : 'urls'} found based on your search
            </Typography>
            )
          : null}
        <FlexFill />
        <div className={classes.toolbarActions}>
          <Button
            size='large'
            disabled={!reviews}
            onClick={handleClear}
            startIcon={<CloseIcon />}
          >
            Clear Filter
          </Button>
          <Button
            size='large'
            variant='contained'
            disabled={!reviews}
            color='secondary'
            onClick={handleCreateCsvExportClick}
            startIcon={<SaveIcon />}
          >
            Create Export
          </Button>
        </div>
      </Toolbar>
      <DashBoardCard loading={reviewsLoading}>
        <Toolbar>
          <FlexFill />
          <ColumnSelector
            columns={ATTRIBUTES}
            visibleColumns={visibleColumns}
            onVisibleColumnsChange={handleColumsChange}
            getColumnHeader={renderColumnHeaderWithCta}
            isColumnSelectionDisabled={isColumnSelectionDisabled}
          />
        </Toolbar>
        <DataTable
          rowsPerPage={REVIEWS_LIST_PAGE_SIZE}
          data={reviews?.items?.map(x => x) || []}
          columns={columns}
          onRowClick={handleRowClick}
          visibleColumns={visibleColumns}
          stickyColumn={stickyColumn}
        />

        <Toolbar>
          <FlexFill />
          <div className={classes.pagination}>
            <Typography component='span'>
              {(page - 1) * REVIEWS_LIST_PAGE_SIZE} - {Math.min(totalRows, page * REVIEWS_LIST_PAGE_SIZE)} of {totalRows}
            </Typography>
            <IconButton
              onClick={() => setPage(page => page - 1)}
              disabled={page <= 1}
            >
              <KeyboardArrowLeft />
            </IconButton>
            {user?.capabilities.canPaginate
              ? (
                <IconButton
                  onClick={() => setPage(page => page + 1)}
                  disabled={page >= Math.min(lastPage, REVIEWS_LIST_MAX_PAGES)}
                >
                  <KeyboardArrowRight />
                </IconButton>
                )
              : (
                <Tooltip title='Upgrade your plan to view more pages'>
                  <span>

                    <IconButton disabled><KeyboardArrowRight /></IconButton>
                  </span>
                </Tooltip>
                )}
          </div>
        </Toolbar>
      </DashBoardCard>
      {value
        ? (
          <CsvExportDialog
            filter={value}
            open={csvExportDialogOpen}
            onClose={handleCsvExportDialogClose}
            initialColumns={visibleColumns}
          />
          )
        : null}
      {selectedReview
        ? (
          <ReviewDetailsDialog
            open={reviewDetailsDialogOpen}
            onClose={() => setReviewDetailsDialogOpen(false)}
            review={selectedReview}
            isColumnSelectionDisabled={isColumnSelectionDisabled}
            getColumnCtaMessage={getColumnCtaMessage}
            visibleColumns={visibleColumns}
            onVisibleColumnsChange={handleColumsChange}
          />
          )
        : null}
    </div>
  );
}
