import { filterifyQuery } from '@wooindex/common/filter';
import { Filter } from '@wooindex/common/types';
import bikeIllustrationUrl from './illustrations/bike.svg';
import shopifyIllustrationUrl from './illustrations/shopify.svg';
import adsIllustrationUrl from './illustrations/ads.svg';
import fashionIllustrationUrl from './illustrations/fashion.svg';
import salesforceIllustrationUrl from './illustrations/salesforce.svg';
import securityIllustrationUrl from './illustrations/security.svg';
import ukIllustrationUrl from './illustrations/uk.svg';
import websiteIllustrationUrl from './illustrations/website.svg';

export interface Example {
  title: string
  image: string,
  filter: Filter
}

export interface ExampleCategory {
  title: string
  examples: Example[]
}

const examples = [
  {
    title: '',
    examples: [
      {
        title: 'All eCommerce websites using Shopify',
        image: shopifyIllustrationUrl,
        filter: filterifyQuery({
          technologies: ['shopify']
        })
      },
      {
        title: 'Sites boosting traffic with paid advertising',
        image: adsIllustrationUrl,
        filter: filterifyQuery({
          technologies: ['google-adwords-tracking', 'facebook-pixel', 'twitter-conversion-tracking']
        })
      },
      {
        title: 'Businesses caring about GDPR and CCPA privacy laws',
        image: securityIllustrationUrl,
        filter: filterifyQuery({
          technologies: ['osano', 'cookiebot', 'onetrust', 'quantcast-choice', 'trustarc', 'iubenda', 'didomi', 'crownpeak', 'cookie-script']
        })
      },
      {
        title: 'Companies using Salesforce to connect with their customers',
        image: salesforceIllustrationUrl,
        filter: filterifyQuery({
          technologies: ['pardot', 'salesforce-commerce-cloud', 'salesforce']
        })
      },
      {
        title: 'Fashion houses doing advanced web analytics',
        image: fashionIllustrationUrl,
        filter: filterifyQuery({
          search: 'fashion',
          technologies: ['google-analytics-enhanced-ecommerce', 'piwik', 'snowplow']
        })
      },
      {
        title: 'Vendors selling cycling equipment online',
        image: bikeIllustrationUrl,
        filter: filterifyQuery({
          search: 'bike',
          technologies: ['braintree', 'paypal', 'stripe']
        })
      },
      {
        title: 'Sites created on popular website builders',
        image: websiteIllustrationUrl,
        filter: filterifyQuery({
          technologies: ['squarespace', 'weebly', 'wix']
        })
      },
      {
        title: 'All eCommerce websites in the United Kingdom',
        image: ukIllustrationUrl,
        filter: filterifyQuery({
          countries: ['GB'],
          technologies: ['shopify', 'woocommerce', 'magento']
        })
      }
    ]
  }
];

export default examples;
