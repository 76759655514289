import * as React from 'react';
import TextWithIcon, { Severity } from '../TextWithIcon';
import { CRITERIUM_STATUS_DISPLAY_NAME } from '@wooindex/common/attributes';
import { CriteriumStatus } from '@wooindex/common/types';

export interface CriteriumStatusProps {
  value?: CriteriumStatus | null
}

function criteriumStatusSeverity (status: CriteriumStatus): Severity {
  switch (status) {
    case 'bad': return 'error';
    case 'average': return 'warning';
    case 'good': return 'success';
    default: return 'info';
  }
}

export default function CriteriumStatusDisplay ({ value }: CriteriumStatusProps) {
  return typeof value === 'string'
    ? (
      <TextWithIcon severity={criteriumStatusSeverity(value)}>
        {CRITERIUM_STATUS_DISPLAY_NAME[value] || value}
      </TextWithIcon>
      )
    : null;
}
