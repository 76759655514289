import * as React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

import SuccessIcon from '@material-ui/icons/CheckCircleOutlined';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutlineOutlined';
import InfoIcon from '@material-ui/icons/InfoOutlined';

const ICON_MAP = {
  success: SuccessIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '&$start': {
      flexDirection: 'row'
    },
    '&$end': {
      flexDirection: 'row-reverse'
    }
  },
  error: {},
  success: {},
  warning: {},
  info: {},
  start: {},
  end: {},
  icon: {
    display: 'inline-flex',
    '$error &': {
      color: theme.palette.error.main
    },
    '$warning &': {
      color: theme.palette.warning.main
    },
    '$success &': {
      color: theme.palette.success.main
    },
    '$info &': {
      color: theme.palette.info.main
    }
  },
  text: {
    '$start &': {
      marginLeft: theme.spacing(1)
    },
    '$end &': {
      marginRight: theme.spacing(1)
    }
  }
}));

export type Severity = 'success' | 'error' | 'info' | 'warning'

interface TextWithIconProps {
  children: React.ReactNode
  icon?: React.ReactElement
  severity?: Severity
  position?: 'start' | 'end'
}

function getIcon (severity: Severity): React.ReactElement {
  const Icon = ICON_MAP[severity];
  return <Icon fontSize='small' />;
}

export default function TextWithIcon ({
  children,
  severity = 'info',
  icon = getIcon(severity),
  position = 'start'
}: TextWithIconProps) {
  const classes = useStyles();
  const severityClass = severity ? classes[severity] : null;
  const positionClass = classes[position];
  return (
    <div className={clsx(classes.root, severityClass, positionClass)}>
      <span className={classes.icon}>{icon}</span>
      <Typography noWrap className={classes.text}>{children}</Typography>
    </div>
  );
}
