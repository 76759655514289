import * as React from 'react';

/**
 * hook that keeps state globally on the window and stores it when closed.
 * It's intentionally storing it only on window close to prevent sibling tabs from messing
 * up each other's state.
 *
 * This hook works as a drop-in replacement for react.useState
 */

declare global {
  interface Window {
    __persistedState?: Map<string, any>
  }
}

if (typeof window !== 'undefined') {
  window.__persistedState = new Map();

  window.addEventListener('unload', () => {
    // only during page unload we store everything in localStorage
    for (const [key, value] of window.__persistedState!.entries()) {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  });
}

interface UsePersistedStateOptions<S> {
  initialValue?: S
}

export default function usePersistedState<S = unknown> (key: string, { initialValue }: UsePersistedStateOptions<S> = {}): [S | undefined, (value: S) => void] {
  // Intentionally doesn't support initial state to avoid hydration mismatches between SSR and CSR
  const [value, setValue] = React.useState<S>();

  React.useEffect(() => {
    if (initialValue) {
      window.__persistedState!.set(key, initialValue);
    }
    let tabValue: S | undefined = window.__persistedState!.get(key);
    if (!tabValue) {
      const persistedValue = window.localStorage.getItem(key);
      if (persistedValue) {
        tabValue = JSON.parse(persistedValue);
        window.__persistedState!.set(key, tabValue);
      }
    }
    if (tabValue !== undefined) {
      setValue(tabValue);
    }
  }, [initialValue, key]);

  const setPersistedValue = React.useCallback((newValue: S) => {
    // intermediate updates are only stored on the window global
    window.__persistedState!.set(key, newValue);
    setValue(newValue);
  }, [key]);

  return [value, setPersistedValue];
}
