import * as React from 'react';
import {
  makeStyles,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  slideContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: '100%'
  },
  illustration: {
    width: 110,
    height: 110
  },
  cardContent: {
    flex: 1,
    maxHeight: 110
  }
}));

interface SlideProps {
  className?: string
  title: string
  image: string
  onClick: () => void
}

export default function ExampleCard ({ className, title, image, onClick }: SlideProps) {
  const classes = useStyles();
  return (
    <Card className={className} onClick={onClick} elevation={3}>
      <CardActionArea className={classes.slideContent}>
        <CardMedia image={image} className={classes.illustration} title={title} />
        <CardContent className={classes.cardContent}>
          <Typography variant='body2' color='textSecondary' component='p'>
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
