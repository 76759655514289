import * as React from 'react';
import { makeStyles, lighten, darken } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  badge: {
    background: lighten(theme.palette.info.main, 0.7),
    fontSize: '0.7em',
    color: darken(theme.palette.info.main, 0.9),
    borderRadius: 10,
    padding: '2px 6px',
    marginLeft: theme.spacing(1)
  }
}));

export default function CtaBadge ({ children }: { children: React.ReactNode }) {
  const classes = useStyles();
  return <span className={classes.badge}>{children}</span>;
}
