"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.alphabeticCompare = exports.removeDiacritics = void 0;
function removeDiacritics(str) {
    // https://stackoverflow.com/a/37511463
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
exports.removeDiacritics = removeDiacritics;
function alphabeticCompare(propertyGetter) {
    if (propertyGetter === void 0) { propertyGetter = String; }
    return function (item1, item2) {
        var string1 = propertyGetter(item1);
        var string2 = propertyGetter(item2);
        return string1.localeCompare(string2, 'en', { sensitivity: 'base' });
    };
}
exports.alphabeticCompare = alphabeticCompare;
