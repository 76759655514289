import * as React from 'react';
import Autocomplete, { AutocompleteRenderGroupParams, AutocompleteProps } from '@material-ui/lab/Autocomplete';
import { makeStyles, useTheme, ListSubheader, useMediaQuery } from '@material-ui/core';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import mergeClasses from '../lib/mergeClasses';

const LISTBOX_PADDING = 8;

function renderRow (props: ListChildComponentProps) {
  const { data, index, style: { top, ...style } } = props;

  return React.cloneElement(data[index], {
    style: { ...style, top: (top as number) + LISTBOX_PADDING }
  });
}

const outerElementPropsContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>(function OuterElementType (props, ref) {
  const outerProps = React.useContext(outerElementPropsContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache (data: unknown) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

const ListboxComponent = React.forwardRef(function ListboxComponent (
  props: React.PropsWithChildren<{}>,
  ref: React.Ref<HTMLDivElement>
) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const gridRef = useResetCache(itemCount);

  const getChildSize = (child: React.ReactNode) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }

    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  return (
    <div ref={ref}>
      <outerElementPropsContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width='100%'
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType='ul'
          itemSize={(index: number) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </outerElementPropsContext.Provider>
    </div>
  );
});

const renderGroup = ({ key, group, children }: AutocompleteRenderGroupParams) => [
  <ListSubheader key={key} component='div'>{group}</ListSubheader>,
  children
];

const useStyles = makeStyles({
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0
    }
  }
});

export interface VirtualizedAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'ListboxComponent' | 'renderGroup'> {
  virtualize?: boolean
}

const virtualizedProps = {
  ListboxComponent: ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>,
  renderGroup
};

export default function VirtualizedAutocomplete<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
> ({ classes, virtualize, ...props }: VirtualizedAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  const virtualizedClasses = useStyles();
  return (
    <Autocomplete<T, Multiple, DisableClearable, FreeSolo>
      classes={mergeClasses(classes || {}, virtualize ? virtualizedClasses : {})}
      {...(virtualize && virtualizedProps)}
      {...props}
    />
  );
}
