import clsx from 'clsx';

interface Classes { [key: string]: string | undefined }

function mergeClasses <C1 extends Classes, C2 extends Classes>(c1: C1, c2: C2): C1 & C2
function mergeClasses <C1 extends Classes, C2 extends Classes, C3 extends Classes>(c1: C1, c2: C2, c3: C3): C1 & C2 & C3
function mergeClasses (...classesArray: Classes[]): Classes {
  const result: { [key: string]: string } = {};

  for (const classes of classesArray) {
    for (const [key, value] of Object.entries(classes)) {
      result[key] = clsx(result[key], value);
    }
  }

  return result;
}

export default mergeClasses;
