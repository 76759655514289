import * as React from 'react';
import useIconClass, { IconProps } from './useIconClass';

interface TechnologyIconProps extends IconProps {
  url: string
  style?: React.CSSProperties
}

export default React.forwardRef(function Favicon (props: TechnologyIconProps, ref: React.ForwardedRef<HTMLImageElement>) {
  const { url, style } = props;
  const iconClass = useIconClass(props);

  return (
    <img
      ref={ref}
      style={style}
      className={iconClass}
      alt={`Favicon for ${url}`}
      src={`https://www.google.com/s2/favicons?sz=48&domain=${url}`}
    />
  );
});
