import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { QuantizedScore } from '@wooindex/common/types';

interface MeterBarProps {
  value?: number | QuantizedScore | null
}

const WIDTH = 30; // px
const HEIGHT = 16; // px

const useStyles = makeStyles(theme => ({
  root: {
    width: WIDTH,
    height: HEIGHT,
    display: 'inline-block',
    position: 'relative'
  },
  triangle: {
    width: 0,
    height: 0,
    borderWidth: 0,
    borderBottomWidth: HEIGHT,
    borderLeftWidth: WIDTH,
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderBottomColor: theme.palette.grey[300],
    position: 'absolute',
    bottom: 0,
    left: 0
  },
  bg: {
    borderBottomColor: theme.palette.grey[300]
  },
  meter: {
    borderBottomColor: theme.palette.grey[800],
    transformOrigin: 'bottom left',
    '$value-unknown &': {
      transform: 'scale(0)'
    },
    '$value-very-low &': {
      transform: 'scale(0.2)'
    },
    '$value-low &': {
      transform: 'scale(0.4)'
    },
    '$value-medium &': {
      transform: 'scale(0.6)'
    },
    '$value-high &': {
      transform: 'scale(0.8)'
    },
    '$value-very-high &': {
      transform: 'scale(1)'
    }
  },
  'value-unknown': {},
  'value-very-low': {},
  'value-low': {},
  'value-medium': {},
  'value-high': {},
  'value-very-high': {}
}));

export default React.forwardRef<HTMLDivElement, MeterBarProps>(({ value = 0, ...props }: MeterBarProps, ref) => {
  const classes = useStyles();
  const meterClass = typeof value === 'string' ? classes[`value-${value}` as const] : undefined;
  const meterStyle = typeof value === 'string' ? undefined : { transform: `scale(${Number(value)})` };
  // we need to pass through all props in order for @material-ui/core/Tooltip to work
  return (
    <div {...props} ref={ref} className={clsx(classes.root, meterClass)}>
      <div className={clsx(classes.triangle, classes.bg)} />
      <div className={clsx(classes.triangle, classes.meter)} style={meterStyle} />
    </div>
  );
});
