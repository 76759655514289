import * as React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { Filter } from '@wooindex/common/types';
import ExampleCard from './ExampleCard';
import exampleCategories, { Example } from './data';

const useStyles = makeStyles(theme => ({
  category: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: theme.spacing(2, 0)
  },
  categoryTitle: {
    margin: theme.spacing(0, 0, 2, 0)
  }
}));

interface ExampleCategoryProps {
  title?: React.ReactNode
  examples?: Example[]
  onChange?: (filter: Filter) => void
}

function ExampleCategory ({ title, examples = [], onChange = () => {} }: ExampleCategoryProps) {
  const classes = useStyles();

  const cards = React.useMemo(() => examples.map((example, i) => (
    <Grid item key={i} xs={12} sm={6} md={4} lg={3}>
      <ExampleCard
        onClick={() => onChange(example.filter)}
        image={example.image}
        title={example.title}
      />
    </Grid>
  )), [examples, onChange]);

  return (
    <div className={classes.category}>
      <Typography variant='body1' className={classes.categoryTitle}>{title}</Typography>
      <Grid container spacing={3}>
        {cards}
      </Grid>
    </div>
  );
}

interface ExamplesProps {
  onChange?: (filter: Filter) => void
  className?: string
}

export default function Examples ({
  onChange = () => {},
  className
}: ExamplesProps) {
  const categories = React.useMemo(() => exampleCategories.map((exampleCategory, i) => (
    <ExampleCategory
      key={i}
      title={exampleCategory.title}
      examples={exampleCategory.examples}
      onChange={onChange}
    />
  )), [onChange]);

  return (
    <div className={className}>
      {categories}
    </div>
  );
}
