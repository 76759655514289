import * as React from 'react';
import { Review, AttributeKey, QuantizedScore } from '@wooindex/common/types';
import {
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link as MuiLink,
  Typography,
  Grid,
  Box,
  Tooltip,
  Checkbox
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  ATTRIBUTES,
  AttributeType,
  AttributeValueType,
  AttributeValueTypeOf,
  ATTRIBUTE_DESCRIPTORS,
  QUANTIZED_SCORE_DISPLAY_NAME
} from '@wooindex/common/attributes';
import { Link, useI18n } from '../i18n';
import TechnologyTags from '../TechnologyTags';
import Tags from '../Tags';
import FlagIcon from '../FlagIcon';
import TextWithIcon from '../TextWithIcon';
import Favicon from '../Favicon';
import CriteriumStatusDisplay from './CriteriumStatusDisplay';
import { toggleItem } from '@wooindex/common/collections';

interface ReviewDetailsDialogProps {
  open: boolean
  onClose?: () => void
  review: Review
  isColumnSelectionDisabled?: (column: AttributeKey) => boolean
  visibleColumns?: string[]
  onVisibleColumnsChange?: (newValue: string[]) => void
  getColumnCtaMessage?: (column: string) => React.ReactNode
}

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  screenshot: {
    width: 135,
    height: 75,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius
  },
  columnNameCell: {
    fontWeight: 'bold'
  },
  valueCell: {
    color: theme.palette.accent.main
  },
  titleLink: {
    color: theme.palette.accent.main,
    marginLeft: theme.spacing(1)
  }
}));

interface FormattedDateProps {
  value?: Date | number | null
  format?: 'medium' | 'mediumDate'
}

function FormattedDate ({ value, format = 'medium' }: FormattedDateProps) {
  const { dateTimeFormat } = useI18n();
  return value ? <>{dateTimeFormat[format].format(value)}</> : null;
}

interface CountryLabelProps {
  countryCode: string
}

function CountryLabel ({ countryCode }: CountryLabelProps) {
  const { dictionary } = useI18n();
  return (
    <TextWithIcon icon={<FlagIcon countryCode={countryCode} fontSize='small' />}>
      {dictionary.country[countryCode] || countryCode}
    </TextWithIcon>
  );
}

interface FormatQuantizedScoreProps {
  value?: QuantizedScore | null
}

function FormatQuantizedScore ({ value }: FormatQuantizedScoreProps) {
  return (
    <Typography>
      {value ? QUANTIZED_SCORE_DISPLAY_NAME[value] : null}
    </Typography>
  );
}

interface AttributeValueRenderer<T = any> {
  (value?: T | null): React.ReactNode
}

/**
 * Column definitions by atribute datatype
 */
const valueTypeRenderers: {
  [type in AttributeType]: AttributeValueRenderer<AttributeValueType[type]>
} = {
  string: value => value,
  number: value => value,
  // eslint-disable-next-line react/display-name
  list: value => <Tags size='small' limit={3} value={value || []} />,
  // eslint-disable-next-line react/display-name
  status: value => <CriteriumStatusDisplay value={value} />,
  // eslint-disable-next-line react/display-name
  quantized: value => <FormatQuantizedScore value={value} />,
  // eslint-disable-next-line react/display-name
  date: value => <FormattedDate value={value} format='mediumDate' />
};

const valueRenderers: {
  [key in AttributeKey]?: AttributeValueRenderer<AttributeValueTypeOf<key>>
} = {
  // eslint-disable-next-line react/display-name
  url: value => <MuiLink color='inherit' href={`http://${value}`}>{value}</MuiLink>,
  // eslint-disable-next-line react/display-name
  country: value => value ? <CountryLabel countryCode={value} /> : null,
  // eslint-disable-next-line react/display-name
  technologies: value => <TechnologyTags limit={3} value={value || []} />
};

function renderValue (review: Review, attributeKey: AttributeKey): React.ReactNode {
  const { type } = ATTRIBUTE_DESCRIPTORS[attributeKey];
  const renderValue = valueRenderers[attributeKey] || valueTypeRenderers[type];
  return renderValue ? renderValue(review[attributeKey] as any) : '???';
}

export default function ReviewDetailsDialog ({
  review,
  open,
  onClose,
  visibleColumns = [],
  onVisibleColumnsChange,
  isColumnSelectionDisabled = () => true,
  getColumnCtaMessage
}: ReviewDetailsDialogProps) {
  const classes = useStyles();

  const [visibleColumnsInput, setVisibleColumnsInput] = React.useState(visibleColumns);
  React.useEffect(() => setVisibleColumnsInput(visibleColumns), [visibleColumns]);

  const handleClose = () => {
    onVisibleColumnsChange?.(visibleColumnsInput);
    onClose?.();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth='lg'>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item>
            {review.screenshot ? <img className={classes.screenshot} src={review.screenshot} alt={`screenshot of ${review.url}`} /> : null}
          </Grid>
          <Grid item>
            <Favicon url={review.url} fontSize='small' />
            <Link variant='h4' className={classes.titleLink} href={`http://${review.url}`}>{review.url}</Link>
            <Typography variant='h5'>Available attributes:</Typography>
          </Grid>
        </Grid>
      </Box>
      <IconButton aria-label='close' className={classes.closeButton} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Attribute</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ATTRIBUTES.map(attributeKey => {
                const isVisibleInTable = visibleColumnsInput.includes(attributeKey);
                const handleChange = () => setVisibleColumnsInput(input => toggleItem(input, attributeKey));
                const ctaMesage = getColumnCtaMessage?.(attributeKey);
                return (
                  <TableRow key={attributeKey}>
                    <TableCell>
                      {ctaMesage
                        ? <Tooltip title={ctaMesage}><span><Checkbox disabled /></span></Tooltip>
                        : (
                          <Tooltip title={`${isVisibleInTable ? 'Hide' : 'Show'} this column in the table`}>
                            <Checkbox
                              size='small'
                              checked={isVisibleInTable}
                              onChange={handleChange}
                              disabled={isColumnSelectionDisabled(attributeKey)}
                            />
                          </Tooltip>
                          )}
                    </TableCell>
                    <TableCell className={classes.columnNameCell} component='th' scope='row'>
                      {ATTRIBUTE_DESCRIPTORS[attributeKey].name}
                    </TableCell>
                    <TableCell>
                      {ATTRIBUTE_DESCRIPTORS[attributeKey].description}
                    </TableCell>
                    <TableCell className={classes.valueCell}>
                      {renderValue(review, attributeKey)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
}
