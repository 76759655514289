import * as React from 'react';
import {
  Container,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Filter } from '@wooindex/common/types';
import DashboardFilter, { DashboardFilterField } from './DashboardFilter';
import { useUser } from '../../lib/user';
import Examples from './Examples';
import ReviewsData from './ReviewsData';

const useStyles = makeStyles(theme => ({
  filter: {
    background: theme.palette.accent.main,
    color: theme.palette.accent.contrastText
  },
  dataGrid: {
    margin: theme.spacing(0, 0, 2, 0)
  },
  emptyStateMessage: {
    margin: theme.spacing(4, 0)
  }
}));

interface EmptyFilterContentProps {
  onChange?: (newValue: Filter) => void
}

function EmptyFilterContent ({ onChange }: EmptyFilterContentProps) {
  const classes = useStyles();
  return (
    <div>
      <Typography
        variant='h6'
        align='center'
        className={classes.emptyStateMessage}
      >
        Build your list with the filters above
      </Typography>
      <Typography
        align='center'
        className={classes.emptyStateMessage}
      >
        or start with an example:
      </Typography>
      <Examples onChange={onChange} />
    </div>
  );
}

export interface DashboardProps {
  value?: Filter
  onChange?: (newValue: Filter) => void
  initialColumns?: string[] | null
}

function isEmpty (filter: Filter) {
  return Object.values(filter).every(value => value.length <= 0);
}

export default function Dashboard ({ value, onChange = () => {}, initialColumns }: DashboardProps) {
  const classes = useStyles();
  const { user } = useUser();

  const [filterFieldOpen, setFilterFieldOpen] = React.useState<DashboardFilterField | null>(null);

  return (
    <div>
      <DashboardFilter
        value={value}
        onChange={onChange}
        open={filterFieldOpen}
        onOpen={setFilterFieldOpen}
        onClose={() => setFilterFieldOpen(null)}
        disabled={!user}
      />
      <Container maxWidth='lg'>
        {value && isEmpty(value)
          ? <EmptyFilterContent onChange={onChange} />
          : value
            ? (
              <ReviewsData
                className={classes.dataGrid}
                value={value}
                onChange={onChange}
                disabled={!!filterFieldOpen}
                initialColumns={initialColumns}
              />
              )
            : <div />}
      </Container>
    </div>
  );
}
