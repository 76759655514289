import * as React from 'react';
import { createColumnFilter } from '@wooindex/common/capabilities';
import { ATTRIBUTES } from '@wooindex/common/attributes';
import { union } from '@wooindex/common/collections';
import { useUser } from '../lib/user';

const FIXED_COLUMNS: Set<string> = new Set(['url']);

export default function useColumnSelection () {
  const { user } = useUser();

  return React.useMemo(() => {
    const isColumnFixed = (column: string) => FIXED_COLUMNS.has(column);

    const isColumnAllowed = user ? createColumnFilter(user.capabilities.csvColumns) : () => false;

    const isColumnSelectionDisabled = (column: string) => !isColumnAllowed(column) || isColumnFixed(column);

    const columnHasMonthlyCta = user ? createColumnFilter(user.capabilities.seesColumnsMonthlyCta) : () => false;

    const columnHasYearlyCta = user ? createColumnFilter(user.capabilities.seesColumnsYearlyCta) : () => false;

    const getColumnCtaLabel = (column: string) => {
      if (!isColumnSelectionDisabled(column)) {
        return null;
      }
      if (columnHasMonthlyCta(column)) {
        return 'Monthly plan';
      } else if (columnHasYearlyCta(column)) {
        return 'Yearly plan';
      }
    };

    const getColumnCtaMessage = (column: string) => {
      if (!isColumnSelectionDisabled(column)) {
        return null;
      }
      if (columnHasMonthlyCta(column)) {
        return 'Upgrade to a monthly plan to unlock this column';
      } else if (columnHasYearlyCta(column)) {
        return 'Upgrade to a yearly plan to unlock this column';
      }
    };

    const fixedColumns = ATTRIBUTES.filter(isColumnFixed);

    const sanitizeColumnSelection = (newColumns: string[]) => {
      return union(fixedColumns, newColumns.filter(isColumnAllowed));
    };

    return {
      isColumnSelectionDisabled,
      getColumnCtaLabel,
      getColumnCtaMessage,
      sanitizeColumnSelection
    };
  }, [user]);
}
