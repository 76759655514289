import * as React from 'react';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import {
  makeStyles,
  TextField,
  TextFieldProps,
  InputAdornment,
  IconButton
} from '@material-ui/core';

const useStyles = makeStyles({
  clearIndicator: {
    visibility: 'hidden'
  },
  clearIndicatorDirty: {
    visibility: 'visible'
  }
});

export interface ClearIndicatorProps {
  className?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  color?: 'inherit' | 'primary' | 'secondary' | 'default'
  position?: 'end' | 'start'
}

export function ClearIndicator ({
  className,
  onClick,
  color = 'inherit',
  position = 'end'
}: ClearIndicatorProps) {
  return (
    <InputAdornment position={position} className={className}>
      <IconButton
        className={className}
        color={color}
        tabIndex={-1}
        onClick={onClick}
        edge={position}
        size='small'
      >
        <CloseIcon fontSize='small' />
      </IconButton>
    </InputAdornment>
  );
}

interface InputClearIndicatorProps {
  className?: string
  inputRef: React.RefObject<HTMLInputElement>
}

export function InputClearIndicator ({ inputRef, className }: InputClearIndicatorProps) {
  const classes = useStyles();
  const isDirty = !!inputRef.current?.value;

  const handleClear = React.useCallback(() => {
    if (inputRef.current) {
      // NOTE: Specific hack to hook into the existing input onchange cycle.
      // https://stackoverflow.com/a/46012210/419436
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')?.set!;
      nativeInputValueSetter.call(inputRef.current, '');
      inputRef.current.dispatchEvent(new Event('input', { bubbles: true }));
    }
  }, [inputRef]);

  return (
    <ClearIndicator
      className={clsx(classes.clearIndicator, { [classes.clearIndicatorDirty]: !!isDirty }, className)}
      onClick={handleClear}
    />
  );
}

export default function ClearableInput (props: TextFieldProps) {
  const {
    InputProps
  } = props;

  if (props.inputRef) {
    // This is not supported yet, to make it work we're going to need to merge the
    // inputRef property with the inputRef we use in this component.
    throw new Error('inputref is not supported on ClearableInput');
  }

  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <TextField
      {...props}
      inputRef={inputRef}
      InputProps={{
        ...InputProps,
        endAdornment: <InputClearIndicator inputRef={inputRef} />
      }}
    />
  );
}
