import * as React from 'react';
import { CreateCsvExportInput, CreateCsvExportResult, Filter, AttributeKey } from '@wooindex/common/types';
import { useRouter } from '../i18n';
import * as fetcher from '@wooindex/common/fetcher';
import SelectionList from '../SelectionList';
import LoadingButton from '../LoadingButton';
import { useUser } from '../../lib/user';
import { proposeFileName } from '@wooindex/common/filter';
import {
  makeStyles,
  lighten,
  darken,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { ATTRIBUTES, ATTRIBUTE_DESCRIPTORS } from '@wooindex/common/attributes';
import UpgradePlanCtaMessage from '../UpgradePlanCta';
import CtaBadge from '../CtaBadge';
import useColumnSelection from '../useColumnSelection';

interface CsvExportDialogProps {
  open?: boolean
  onClose?: () => void
  filter: Filter
  initialColumns: string[]
}

async function createCsvExport (name: string, filter: Filter, columns: string[]): Promise<CreateCsvExportResult> {
  return await fetcher.post('/api/csv-exports', {
    json: { name, filter, columns } as CreateCsvExportInput
  }).json() as CreateCsvExportResult;
}

const useStyles = makeStyles(theme => ({
  optionLine: {
    display: 'flex',
    alignItems: 'center'
  },
  columnBadge: {
    background: lighten(theme.palette.info.main, 0.7),
    fontSize: '0.7em',
    color: darken(theme.palette.info.main, 0.9),
    borderRadius: 10,
    padding: '2px 6px',
    marginLeft: theme.spacing(1)
  },
  label: {
    marginTop: theme.spacing(2),
    '&:first-child': {
      marginTop: 0
    }
  }
}));

export default function CsvExportDialog ({ open = false, onClose, filter, initialColumns }: CsvExportDialogProps) {
  const { user } = useUser();

  const classes = useStyles();

  const defaultName = proposeFileName(filter);
  const [name, setName] = React.useState(defaultName);
  React.useEffect(() => setName(defaultName), [defaultName]);

  const {
    isColumnSelectionDisabled,
    getColumnCtaLabel,
    sanitizeColumnSelection
  } = useColumnSelection();

  const [nameError, setNameError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [columns, setColumns] = React.useState<string[]>(initialColumns);
  const router = useRouter();

  const handleCreateExportClick = () => {
    setLoading(true);
    createCsvExport(name, filter, columns)
      .then((result) => {
        if (result.error) {
          switch (result.error.code) {
            case 'EXISTS': setNameError('An export with this name already exists, please use a different name');
          }
        } else {
          router.push('/dashboard/exports');
        }
      }, () => {
        setError('An unknown error happened');
      })
      .finally(() => setLoading(false));
  };

  const handleColumsChange = React.useCallback((newColumns: string[]) => {
    setColumns(sanitizeColumnSelection(newColumns));
  }, [sanitizeColumnSelection]);

  React.useEffect(() => {
    if (!open && initialColumns) {
      handleColumsChange(initialColumns);
    }
  }, [open, initialColumns, handleColumsChange]);

  const renderColumnHeaderWithCta = (attr: string) => {
    const ctaLabel = getColumnCtaLabel(attr);
    return (
      <>
        {ATTRIBUTE_DESCRIPTORS[attr as AttributeKey]?.name}
        {ctaLabel ? <CtaBadge>{ctaLabel}</CtaBadge> : null}
      </>
    );
  };

  if (!user) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth scroll='paper' maxWidth='md'>
      <DialogTitle>Create Export</DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography className={classes.label} color='textSecondary'>Name your export:</Typography>

        <TextField
          autoFocus
          variant='outlined'
          margin='dense'
          id='name'
          fullWidth
          value={name}
          error={!!nameError}
          helperText={nameError}
          onChange={(event) => {
            setNameError(null);
            setName(event.target.value);
          }}
        />

        <Typography className={classes.label} color='textSecondary'>Pick the columns you are interested in:</Typography>

        <SelectionList
          title='All columns'
          options={ATTRIBUTES}
          value={columns}
          onChange={handleColumsChange}
          getOptionPrimaryText={renderColumnHeaderWithCta}
          getOptionDisabled={isColumnSelectionDisabled}
        />

        <UpgradePlanCtaMessage capabilities={user.capabilities} />

        {error ? <DialogContentText>{error}</DialogContentText> : null}
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          variant='contained'
          onClick={handleCreateExportClick}
          color='primary'
          disabled={!user || !name || loading}
          pending={loading}
        >
          Create Export
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
