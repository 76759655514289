import * as React from 'react';

import {
  Column,
  useBlockLayout,
  useResizeColumns,
  useTable,
  HeaderGroup,
  Row
} from 'react-table';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  createSvgIcon,
  makeStyles
} from '@material-ui/core';
import { complement } from '@wooindex/common/collections';
import clsx from 'clsx';

const ZINDEX_SEPARATOR = 1;
const ZINDEX_STICKY_COLUMN = ZINDEX_SEPARATOR + 1;

const useStyles = makeStyles(theme => ({
  columnSeparator: {
    color: theme.palette.grey[300],
    display: 'inline-flex',
    alignItems: 'center',
    height: '100%',
    position: 'absolute',
    right: 0,
    top: 0,
    transform: 'translateX(50%)',
    zIndex: ZINDEX_SEPARATOR,
    touchAction: 'none',
    cursor: 'ew-resize',
    '&:hover': {
      color: theme.palette.action.active
    }
  },
  isScrolled: {},
  tableRoot: {
    tableLayout: 'fixed',
    width: 'unset',
    '& .MuiTableRow-hover': {
      cursor: 'pointer'
    },
    '& .MuiTableCell-head': {
      fontSize: '1em',
      textTransform: 'unset'
    },
    '& .MuiTableCell-sizeSmall': {
      paddingRight: 16
    },
    '& .MuiTableCell-alignCenter': {
      justifyContent: 'center'
    },
    '&$isScrolled .MuiTableCell-body$stickyColumn': {
      '&:after': {
        position: 'absolute',
        content: '""',
        width: 30,
        right: -30,
        top: 0,
        height: '100%',
        boxShadow: 'inset 10px 0 8px -8px rgb(0 0 0 / 15%)'
      }
    }
  },
  headerText: {
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  paginationActions: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  },
  stickyColumn: {
    position: 'sticky !important' as any,
    left: 0,
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: ZINDEX_STICKY_COLUMN,
    '.MuiTableRow-root.MuiTableRow-hover:hover &': {
      backgroundColor: theme.palette.action.hover
    }
  }
}));

const SeparatorIcon = createSvgIcon(<path d='M11 19V5h2v14z' />, 'Separator');

interface ColumnSeparatorProps<D extends object> {
  column: HeaderGroup<D>
}

function ColumnSeparator<D extends object> ({ column }: ColumnSeparatorProps<D>) {
  const classes = useStyles();
  return (
    <div {...column.getResizerProps({ className: classes.columnSeparator })}>
      <SeparatorIcon />
    </div>
  );
}

interface DataTableprops<D extends object> {
  columns: (Column<D> & { accessor: string })[]
  data: D[]
  visibleColumns?: string[]
  onRowClick?: (params: { row: D }) => void
  rowsPerPage?: number
  stickyColumn?: boolean
}

export default function DataTable <D extends object> ({
  columns: columnsProp,
  data,
  onRowClick = () => {},
  visibleColumns = [],
  rowsPerPage = 0,
  stickyColumn
}: DataTableprops<D>) {
  const classes = useStyles();

  const getHiddenColumns = React.useCallback(() => {
    return complement(columnsProp.map(column => column.accessor), visibleColumns);
  }, [visibleColumns, columnsProp]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setHiddenColumns
  } = useTable<D>(
    {
      initialState: {
        hiddenColumns: getHiddenColumns()
      },
      columns: columnsProp,
      data
    },
    // Look at ./react-table-types.d.ts to enable type declarations for these plugins:
    useBlockLayout,
    useResizeColumns
  );

  React.useEffect(() => {
    setHiddenColumns(getHiddenColumns());
  }, [setHiddenColumns, getHiddenColumns]);

  const emptyRows = rowsPerPage - rows.length;

  const [isScrolled, setIsScrolled] = React.useState(false);
  const handleScroll = React.useCallback((event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    setIsScrolled(event.currentTarget.scrollLeft > 0);
  }, []);

  return (
    <TableContainer onScroll={handleScroll}>
      <Table
        size='small' {...getTableProps({
          className: clsx(classes.tableRoot, { [classes.isScrolled]: isScrolled })
        })}
      >
        <TableHead>
          {headerGroups.map((headerGroup: HeaderGroup<D>) => (
            /* eslint-disable-next-line react/jsx-key */
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: HeaderGroup<D>, i) => (
                /* eslint-disable-next-line react/jsx-key */
                <TableCell
                  align={column.align}
                  // react-table doesn't seem to enforce maxWidth in useFlexLayout, so we'll add it
                  {...column.getHeaderProps({ className: clsx({ [classes.stickyColumn]: stickyColumn && i === 0 }) })
                }
                >
                  <span className={classes.headerText}>{column.render('Header')}</span>
                  <ColumnSeparator column={column} />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {rows.map((row: Row<D>) => {
            prepareRow(row);
            return (
            /* eslint-disable-next-line react/jsx-key */
              <TableRow hover={!!onRowClick} onClick={() => onRowClick({ row: row.original })} {...row.getRowProps()}>
                {row.cells.map((cell, i) => {
                  return (
                  /* eslint-disable-next-line react/jsx-key */
                    <TableCell
                      align={cell.column.align}
                      {...cell.getCellProps({
                        style: { display: 'flex', alignItems: 'center' },
                        className: clsx({ [classes.stickyColumn]: stickyColumn && i === 0 })
                      })}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
          {emptyRows > 0
            ? (
              <TableRow style={{ height: emptyRows * 33 }} />
              )
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
