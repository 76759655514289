import * as React from 'react';

import {
  Switch,
  Popover,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Box,
  makeStyles,
  Button
} from '@material-ui/core';
import { toggleItem } from '@wooindex/common/collections';
import ColumnsIcon from '@material-ui/icons/ViewColumn';
import DropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles(() => ({
  formControlLabel: {
    '& .MuiFormControlLabel-label': {
      flex: 1
    }
  }
}));

interface ColumnSelectorProps {
  columns: readonly string[]
  visibleColumns: string[]
  onVisibleColumnsChange: (newValue: string[]) => void
  getColumnHeader?: (column: string) => React.ReactNode
  isColumnSelectionDisabled?: (column: string) => boolean
}

export default function ColumSelector ({
  columns,
  visibleColumns = [],
  onVisibleColumnsChange = () => {},
  getColumnHeader = String,
  isColumnSelectionDisabled = () => true
}: ColumnSelectorProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorElm] = React.useState<HTMLButtonElement | null>(null);

  const [input, setInput] = React.useState<string[]>(visibleColumns);
  React.useEffect(() => setInput(visibleColumns), [visibleColumns]);

  const handleClose = () => {
    onVisibleColumnsChange(input);
    setAnchorElm(null);
  };

  return (
    <>
      <Button
        variant='outlined'
        size='small'
        startIcon={<ColumnsIcon />}
        endIcon={<DropDownIcon />}
        onClick={event => setAnchorElm(event.currentTarget)}
      >
        Select Columns
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <Box p={2}>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Select visible columns</FormLabel>
            <FormGroup>
              {columns.map(column => {
                const checked = input.includes(column);
                const handleChange = () => setInput(input => toggleItem(input, column));
                return (
                  <FormControlLabel
                    className={classes.formControlLabel}
                    key={column}
                    disabled={isColumnSelectionDisabled(column)}
                    control={<Switch checked={checked} onChange={handleChange} />}
                    label={getColumnHeader(column)}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        </Box>
      </Popover>
    </>
  );
}
