import { Card, CardHeader, LinearProgress, makeStyles } from '@material-ui/core';
import * as React from 'react';
import clsx from 'clsx';

interface DashBoardCardProps {
  title?: string
  subheader?: string
  loading?: boolean
  image?: string
}

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative'
  },
  loading: {},
  loader: {
    visibility: 'hidden',
    '$loading &': {
      visibility: 'visible'
    }
  }
}));

export default function DashBoardCard ({
  title,
  subheader,
  loading,
  image,
  children
}: React.PropsWithChildren<DashBoardCardProps>) {
  const classes = useStyles();
  return (
    <Card className={clsx(classes.root, { [classes.loading]: loading })}>
      <LinearProgress className={classes.loader} />
      {title || subheader
        ? <CardHeader title={title} subheader={subheader} avatar={<img src={image} alt='' />} />
        : null}
      {children}
    </Card>
  );
}
