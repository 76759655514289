"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidIndexFilter = exports.isPlanChangeAllowed = exports.createColumnFilter = void 0;
var paddle_1 = require("./paddle");
var wildcards_1 = require("./wildcards");
function createColumnFilter(columnList) {
    var columnSet = new Set(columnList);
    if (columnSet.has('*')) {
        return function () { return true; };
    }
    var regExes = columnList
        .filter(function (column) { return /\?\*/.test(column); })
        .map(function (wildcardString) { return wildcards_1.toRegExp(wildcardString); });
    return function (key) { return columnSet.has(key) || regExes.some(function (regex) { return regex.test(key); }); };
}
exports.createColumnFilter = createColumnFilter;
function isPlanChangeAllowed(targetPlanKey, activePlanKey) {
    if (!activePlanKey) {
        return true;
    }
    // prevent potential side effects of changing from a plan to exactly the same plan
    if (targetPlanKey === activePlanKey) {
        return false;
    }
    return paddle_1.getInterval(targetPlanKey) > paddle_1.getInterval(activePlanKey);
}
exports.isPlanChangeAllowed = isPlanChangeAllowed;
function isValidIndexFilter(filter, capabilities) {
    var e_1, _a;
    if (capabilities.maxFilterOptions > 0) {
        try {
            for (var _b = __values(Object.values(filter)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var value = _c.value;
                if (value && value.length > capabilities.maxFilterOptions) {
                    return false;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    return true;
}
exports.isValidIndexFilter = isValidIndexFilter;
