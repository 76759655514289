import * as React from 'react';
import {
  darken,
  lighten,
  makeStyles,
  Divider,
  Typography,
  InputBase,
  Chip,
  InputAdornment,
  Popper,
  Paper,
  Container,
  Switch,
  Grid
} from '@material-ui/core';
import { CountryList, Filter, Technology, TechnologyList } from '@wooindex/common/types';
import { filterifyQuery, parseSearch, serializeFilter, stringifySearch } from '@wooindex/common/filter';
import { ControlledProps } from '../utils';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import { InputClearIndicator } from '../ClearableInput';
import VirtualizedAutocomplete, { VirtualizedAutocompleteProps } from '../VirtualizedAutocomplete';
import { AutocompleteCloseReason, AutocompleteGetTagProps, AutocompleteRenderInputParams } from '@material-ui/lab';
import { useI18n } from '../i18n';
import FlagIcon from '../FlagIcon';
import useSWR from 'swr';
import { omit } from '@wooindex/common/collections';
import { alphabeticCompare } from '@wooindex/common/strings';
import TechnologyIcon from '../TechnologyIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.accent.main,
    color: theme.palette.accent.contrastText,

    '&$open': {
      background: darken(theme.palette.accent.main, 0.1)
    }
  },
  filterContainer: {
    display: 'flex'
  },
  filterSelector: {
    flexGrow: 1,
    flexBasis: 0,
    display: 'flex'
  },
  filterField: {
    display: 'flex',
    width: '100%',
    padding: theme.spacing(2),
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    cursor: 'text',

    '$disabled &': {
      color: 'rgba(256, 256, 256, 0.3)'
    },

    '&:hover': {
      textDecoration: 'none',
      backgroundColor: lighten(theme.palette.accent.main, 0.1)
    },

    '$focused &': {
      backgroundColor: theme.palette.accent.main
    }
  },
  filterIconSmall: {
    height: 16,
    marginLeft: 6
  },
  autocompleteTag: {
    margin: 6
  },
  autocompleteTagChip: {
    background: theme.palette.accent.contrastText
  },
  autocompleteTagChipDeleteIcon: {
    display: 'none',
    '$focused &': {
      display: 'inline-block'
    }
  },
  filterFieldContent: {
    minHeight: 70,
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  filterFieldInput: {
    color: 'inherit',
    flex: 1
  },
  focused: {},
  dirty: {},
  open: {},
  disabled: {
    pointerEvents: 'none'
  },
  cleanIndicator: {
    '$focused &': {
      display: 'none'
    }
  },
  divider: {
    backgroundColor: theme.palette.accent.contrastText
  },
  autocompleteRoot: {
    '&:hover $clearIndicator': {
      visibility: 'hidden'
    },
    '&$dirty$focused $clearIndicator': {
      visibility: 'visible'
    }
  },
  clearIndicator: {
    color: 'inherit',
    visibility: 'hidden',
    '$dirty$focused &': {
      visibility: 'visible'
    }
  },
  optionIcon: {},
  optionName: {
    marginLeft: theme.spacing(1),
    flex: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  optionCount: {
    fontWeight: 'bolder',
    fontSize: '0.75em'
  },
  inputHintPaper: {
    padding: theme.spacing(2),
    maxWidth: 400,
    margin: '4px 0',
    '& ul': {
      paddingLeft: theme.spacing(3)
    }
  }
}));

interface SelectorProps<T> extends ControlledProps<T> {
  open: boolean
  onOpen: () => void
  onClose: () => void
}

interface FilterSelectorProps<T> extends SelectorProps<T> {
  filter: Filter
}

interface FilterFieldProps {
  label: string
  className?: string
  children?: React.ReactNode
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  onMouseDown?: (event: React.MouseEvent<HTMLDivElement>) => void
}

const FilterField = React.forwardRef<HTMLDivElement, FilterFieldProps>(function FilterField ({
  label,
  children,
  onClick = () => {},
  onMouseDown = () => {},
  className
}: FilterFieldProps, ref) {
  const classes = useStyles();

  return (
    <div
      ref={ref}
      className={clsx(classes.filterField, className)}
      onMouseDown={onMouseDown}
      onClick={onClick}
    >
      <Typography variant='h6'>{label}</Typography>
      <div className={classes.filterFieldContent}>
        {children}
      </div>
    </div>
  );
});

interface FilterFieldAutocompleteProps extends ControlledProps<string[]>, Omit<VirtualizedAutocompleteProps<string, true, false, false>, 'renderInput' | 'value' | 'onChange'> {
  label: string
  getOptionIcon: (option: string) => React.ReactNode
  getOptionCount: (option: string) => number
}

function FilterFieldAutocomplete ({
  label,
  value,
  onChange,
  getOptionLabel = (option: string) => option,
  getOptionIcon,
  getOptionCount,
  open,
  onOpen,
  onClose,
  ...props
}: FilterFieldAutocompleteProps) {
  const { numberFormat } = useI18n();
  const classes = useStyles();
  const handleClose = (event: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
    if (onClose) {
      onClose(event, reason);
    }
    onChange(value);
  };
  return (
    <VirtualizedAutocomplete
      className={clsx(classes.filterSelector, { [classes.dirty]: value.length > 0 })}
      classes={{
        root: classes.autocompleteRoot,
        clearIndicator: classes.clearIndicator,
        tag: classes.autocompleteTag,
        focused: classes.focused
      }}
      limitTags={3}
      forcePopupIcon={false}
      disableCloseOnSelect
      multiple
      openOnFocus
      disableListWrap
      autoHighlight
      value={value}
      onChange={(event: React.ChangeEvent<{}>, newValue: string[]) => onChange(newValue)}
      open={open}
      onOpen={onOpen}
      onClose={handleClose}
      getOptionLabel={getOptionLabel}
      renderOption={(option: string) => (
        <>
          <span className={classes.optionIcon}>{getOptionIcon(option)}</span>
          <span className={classes.optionName}>{getOptionLabel(option)}</span>
          <span className={classes.optionCount}>{numberFormat.compact.format(getOptionCount(option))}</span>
        </>
      )}
      renderInput={((params: AutocompleteRenderInputParams) => {
        const { ref, className: inputClassName, ...InputProps } = params.InputProps;
        return (
          <FilterField
            label={label}
            ref={ref}
            onClick={onOpen}
          >
            <InputBase
              type='text'
              className={clsx(inputClassName, classes.filterFieldInput)}
              inputProps={params.inputProps}
              {...InputProps}
              startAdornment={value.length > 0
                ? InputProps.startAdornment
                : <InputAdornment position='start' className={classes.cleanIndicator}><AddIcon /></InputAdornment>}
            />
          </FilterField>
        );
      })}
      renderTags={(value: string[], getTagProps: AutocompleteGetTagProps) => (
        value.map((option: string, index: number) => (
          <Chip
            size='small'
            classes={{
              root: classes.autocompleteTagChip,
              iconSmall: classes.filterIconSmall,
              deleteIcon: classes.autocompleteTagChipDeleteIcon
            }}
            icon={<span>{getOptionIcon(option)}</span>}
            key={index}
            label={getOptionLabel(option)}
            {...getTagProps({ index })}
          />
        ))
      )}
      {...props}
    />
  );
}

function CountrySelector ({
  filter,
  value,
  onChange,
  open,
  onOpen,
  onClose
}: FilterSelectorProps<string[]>) {
  const { dictionary } = useI18n();
  const query = serializeFilter(omit(filter, 'countries'));

  // TODO: replace with `import useSwrImmutable from 'swr/immutable';` when available
  const { data, isValidating } = useSWR<CountryList>(open ? `/api/data/countries?${query}` : null);
  const {
    countries,
    options
  } = React.useMemo(() => {
    const countries = new Map(
      data?.items
        .filter(({ count }) => count > 0)
        .map(country => [country.code, country])
    );
    return {
      countries,
      options: Array.from(countries.keys())
    };
  }, [data]);

  return (
    <FilterFieldAutocomplete
      virtualize
      label='Country'
      options={options}
      loading={isValidating}
      getOptionIcon={code => <FlagIcon fontSize={16} countryCode={code} />}
      getOptionLabel={code => dictionary.country[code] || code}
      getOptionCount={code => countries.get(code)?.count || 0}
      value={value}
      onChange={onChange}
      aria-label='country'
      open={open}
      onOpen={onOpen}
      onClose={onClose}
    />
  );
}

function TechnologySelector ({
  filter,
  value,
  onChange,
  open,
  onOpen,
  onClose
}: FilterSelectorProps<string[]>) {
  const { dictionary } = useI18n();

  const getTechnologyGroup = React.useCallback((technologies: Map<string, Technology>, id: string): string => {
    const categoryId = technologies.get(id)?.category?.[0];
    const categoryName = (categoryId && dictionary.category[categoryId]) || 'Uncategorized';
    return categoryName;
  }, [dictionary]);

  const query = serializeFilter(omit(filter, 'technologies'));

  // TODO: replace with `import useSwrImmutable from 'swr/immutable';` when available
  const { data, isValidating } = useSWR<TechnologyList>(open ? `/api/data/technologies?${query}` : null);
  const {
    technologies,
    options
  } = React.useMemo(() => {
    const technologies = new Map(
      data?.items
        .filter(({ count }) => count > 0)
        .map(technology => [technology.id, technology])
    );
    return {
      technologies,
      options: [...technologies.keys()].sort(alphabeticCompare(id => getTechnologyGroup(technologies, id)))
    };
  }, [data, getTechnologyGroup]);

  return (
    <FilterFieldAutocomplete
      virtualize
      label='Technology'
      options={options}
      loading={isValidating}
      getOptionIcon={id => <TechnologyIcon id={id} fontSize={16} />}
      getOptionLabel={id => dictionary.technology[id] || id}
      getOptionCount={id => technologies.get(id)?.count || 0}
      value={value}
      onChange={onChange}
      groupBy={id => getTechnologyGroup(technologies, id)}
      aria-label='technology'
      open={open}
      onOpen={onOpen}
      onClose={onClose}
    />
  );
}

type Mode = 'all' | 'any'

interface FilterFieldTextInputProps extends SelectorProps<string> {
  label: string
  hint?: React.ReactNode
  modeSelector?: boolean
}

function FilterFieldTextInput ({
  label,
  value,
  onChange,
  hint,
  open,
  onOpen,
  onClose,
  modeSelector
}: FilterFieldTextInputProps) {
  const classes = useStyles();
  const focused = open;
  const inputRef = React.useRef<HTMLInputElement>(null);

  const [mode, setMode] = React.useState<Mode>('all');

  const [input, setInput] = React.useState('');
  React.useEffect(() => setInput(value), [value]);

  const handleClick = () => {
    onOpen();
    inputRef.current?.focus();
  };

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    // Prevent input blur when interacting with the component
    // for instance to allow clicking the clear indicator
    event.preventDefault();
  };

  const handleBlur = () => {
    onChange(input);
    onClose();
  };

  const ref = React.useRef<HTMLDivElement>(null);

  const onKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      inputRef.current?.blur();
    }
  };

  const toggleMode = () => setMode((mode: Mode): Mode => mode === 'all' ? 'any' : 'all');

  return (
    <div className={clsx(classes.filterSelector, { [classes.focused]: focused })} ref={ref}>
      <FilterField
        label={label}
        onClick={handleClick}
        onMouseDown={handleMouseDown}
        className={clsx({ [classes.focused]: focused, [classes.dirty]: !!input })}
      >
        <InputBase
          type='text'
          inputRef={inputRef}
          className={classes.filterFieldInput}
          value={input}
          onChange={event => setInput(event.target.value)}
          onFocus={() => onOpen()}
          onBlur={handleBlur}
          endAdornment={<InputClearIndicator className={classes.clearIndicator} inputRef={inputRef} />}
          onKeyPress={onKeyPress}
          startAdornment={input
            ? null
            : <InputAdornment position='start' className={classes.cleanIndicator}><AddIcon /></InputAdornment>}
        />
        <Popper
          open={focused}
          anchorEl={ref.current}
          style={{ width: ref.current ? ref.current.clientWidth : undefined }}
        >
          <Paper className={classes.inputHintPaper}>
            <Typography component='div'>
              {modeSelector
                ? (
                  <Grid component='label' container alignItems='center' spacing={1}>
                    <Grid item xs>Match words:</Grid>
                    <Grid item>Some</Grid>
                    <Grid item>
                      <Switch checked={mode === 'all'} onChange={toggleMode} />
                    </Grid>
                    <Grid item>All</Grid>
                  </Grid>
                  )
                : null}
            </Typography>
            {hint}
          </Paper>
        </Popper>
      </FilterField>
    </div>
  );
}

export type DashboardFilterField = 'country' | 'technology' | 'url' | 'keyword'

interface DashboardFilterProps extends Partial<ControlledProps<Filter>> {
  disabled?: boolean
  open: DashboardFilterField | null
  onOpen?: (field: DashboardFilterField) => void
  onClose?: () => void
}

export default function DashboardFilter ({
  value,
  onChange,
  open,
  onOpen,
  onClose,
  disabled
}: DashboardFilterProps) {
  const classes = useStyles();

  const [input, setInput] = React.useState(filterifyQuery({}));
  React.useEffect(() => {
    if (value) {
      setInput(value);
    }
  }, [value]);

  const openFields = React.useRef(new Set<DashboardFilterField>());

  const update = () => {
    if (openFields.current.size === 1) {
      onOpen?.([...openFields.current][0]);
    } else if (openFields.current.size <= 0) {
      onClose?.();
    }
  };

  const handleFieldOpen = (id: DashboardFilterField) => () => {
    openFields.current.add(id);
    update();
  };

  const handleFieldClose = (id: DashboardFilterField) => () => {
    openFields.current.delete(id);
    update();
  };

  const isFieldActive = (id: DashboardFilterField) => open === id;

  const wasOpen = React.useRef(!!open);
  React.useEffect(() => {
    const isOpen = !!open;
    if (wasOpen.current !== isOpen) {
      onChange?.(input);
    }
    wasOpen.current = isOpen;
  }, [open, input, onChange]);

  const divider = <Divider className={classes.divider} orientation='vertical' flexItem />;

  return (

    <div className={clsx(classes.root, { [classes.disabled]: disabled, [classes.open]: !!open })}>
      <Container maxWidth='lg'>
        <div className={clsx(classes.filterContainer)}>
          {divider}
          <CountrySelector
            filter={input}
            value={input.countries}
            onChange={countries => setInput({ ...input, countries })}
            open={isFieldActive('country')}
            onOpen={handleFieldOpen('country')}
            onClose={handleFieldClose('country')}
          />
          {divider}
          <TechnologySelector
            filter={input}
            value={input.technologies}
            onChange={technologies => setInput({ ...input, technologies })}
            open={isFieldActive('technology')}
            onOpen={handleFieldOpen('technology')}
            onClose={handleFieldClose('technology')}
          />
          {divider}
          <FilterFieldTextInput
            label='URL'
            value={input.url.join(' ')}
            onChange={url => setInput({ ...input, url: url.split(' ').filter(Boolean) })}
            hint={
              <>
                Search for URL patterns. You can include wildcards, e.g.:
                <ul>
                  <li>
                    <em>example.*</em> will find example.com, example.org, etc.
                  </li>
                  <li>
                    <em>*.io</em> will find URLs within the .io top level domain
                  </li>
                </ul>
              </>
            }
            open={isFieldActive('url')}
            onOpen={handleFieldOpen('url')}
            onClose={handleFieldClose('url')}
          />
          {divider}
          <FilterFieldTextInput
            label='Keyword'
            value={stringifySearch(input.search)}
            onChange={search => setInput({ ...input, search: parseSearch(search) })}
            hint={
              <>
                Search for keywords, separated by a space, in the title. You can use quotes for exact match queries, eg.:
                <ul>
                  <li>
                    <em>chicago pizza</em> will find either <em>chicago</em> OR <em>pizza</em>
                  </li>
                  <li>
                    <em>&quot;chicago pizza&quot;</em> will match the term <em>chicago pizza</em> exactly
                  </li>
                </ul>
              </>
            }
            open={isFieldActive('keyword')}
            onOpen={handleFieldOpen('keyword')}
            onClose={handleFieldClose('keyword')}
          />
          {divider}
        </div>
      </Container>
    </div>
  );
}
