"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.toRegExp = void 0;
function escapeRegExp(string) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
function replaceWildCards(wildCardString, wildCards) {
    if (wildCards.length <= 0) {
        return escapeRegExp(wildCardString);
    }
    var _a = __read(wildCards), _b = __read(_a[0], 2), wildCard = _b[0], subStitute = _b[1], rest = _a.slice(1);
    return wildCardString
        .split(wildCard)
        .map(function (segment) { return replaceWildCards(segment, rest); })
        .join(subStitute);
}
/**
 * Turns a wildcard pattern string into a regular expression. Allowed wildcards are `*` to mach
 * multiple characters and `?` to match a single character. The resulting reghular expression can
 * be used to filter or validate srings against this wildcard pattern. e.g.
 *
 * @example
 * const hostMatcher = toRegExp('*.woorank.com')
 * const isWoorankHost = hostMatcher.test('subdomain.woorank.com') // true
 *
 * @example
 * const hostMatcher = toRegExp(['*.woorank.com', '*.woorank.io'])
 * const isWoorankHost = hostMatcher.test('subdomain.woorank.com') // true
 *
 * @example
 * const hostMatcher = toRegExp({ com: '*.woorank.com', io: '*.woorank.io' })
 * const isWoorankHost = hostMatcher.exec('subdomain.woorank.com').groups
 * // { com: 'subdomain.woorank.com', io: undefined }
 */
function toRegExp(input, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.sensitive, sensitive = _c === void 0 ? false : _c, _d = _b.character, character = _d === void 0 ? '.' : _d;
    var subStitutions = Object.entries({
        '*': character + "*",
        '?': character
    });
    var groups = typeof input === 'string'
        ? [replaceWildCards(input, subStitutions)]
        : Array.isArray(input)
            ? input.map(function (wildcardString) { return replaceWildCards(wildcardString, subStitutions); })
            : Object.entries(input).map(function (_a) {
                var _b = __read(_a, 2), name = _b[0], wildcardString = _b[1];
                return "(?<" + name + ">" + replaceWildCards(wildcardString, subStitutions) + ")";
            });
    return new RegExp("^(?:" + groups.join('|') + ")$", sensitive ? undefined : 'i');
}
exports.toRegExp = toRegExp;
